@import "~antd/dist/antd.dark.less";

::selection {
  background: rgba(250, 250, 250, 0.35);
}

html,
body {
  font-family: "Poppins", sans-serif;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}

body {
  overflow-x: hidden;

  &::-webkit-scrollbar {
    visibility: hidden;
    width: 6px;
  }

  &::-webkit-scrollbar {
    visibility: visible;
  }

  &::-webkit-scrollbar-track {
    background: gray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f0cb65;
    border-radius: 20px;
    border: 0px solid #f0cb65;
  }
}

.legacy2,
.legacy4,
.legacy {
  background: url("./assets/images/background.png");
}

.gold {
  background: url("./assets/images/background-grey.png");
}

.home {
  background-color: #000;
}

.wallet.bitcoin .ant-modal-content,
.bitcoin {
  background: url("./assets/images/background-bitcoin.png") !important;
}

.wallet.usdt .ant-modal-content,
.usdt {
  background: url("./assets/images/background-usdt.png") !important;
}

.wallet.eth .ant-modal-content,
.eth {
  background: url("./assets/images/background-eth.png") !important;
}

.realstate {
  background: url("./assets/images/background-realstate.png");

  .ant-layout-header {
    div,
    span,
    p {
      color: #000 !important;
    }
  }
}

@font-face {
  font-family: "mazzardh_black";
  src: url("./assets/fonts/mazzardh-black-webfont.woff2") format("woff2"),
    url("./assets/fonts/mazzardh-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.ant-layout-footer {
  background-color: transparent;
}

.ant-layout-header {
  background-color: transparent;
  height: auto !important;
  border-bottom: 1px solid #3a3a3a;
}

.ant-menu-dark.ant-menu-horizontal,
.ant-menu-horizontal {
  background-color: transparent !important;
}

.ant-menu-dark .ant-menu-item-selected {
  color: #02225c;
}

.ant-layout {
  background-color: transparent !important;
}

.ant-form-item {
  margin-bottom: 16px !important;
}

@body-background: #02225c;
@primary-color: #f0cb65;
@checkbox-check-color: #02225c;
@checkbox-size: 18px;
@checkbox-check-bg: rgba(0, 0, 0, 0.3);

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
}
.ant-carousel .slick-dots,
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: #fff !important;
}

.ant-carousel .slick-dots li {
  background-color: white;
  color: white;
}
.ant-carousel .slick-dots li.slick-active {
  background-color: white;
  color: white;
}

.ant-collapse-header {
  display: flex;
  width: 100%;
}

